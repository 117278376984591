import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { CompanyDB } from "../components/CompanyDB"

export default function CompanyDetailDialog({ closeDialogHandler, companyName }) {
    const [open, setOpen] = useState(true);
    const companyNameInternal = companyName;

    const { name, title, description, imageUrl, website, impact, relationship, years, stage } =
        CompanyDB.find(company => company.name === companyNameInternal);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 ">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto max-w-3xl py-1 h-full">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6 ">
                                            <div className="flex items-start justify-between">
                                                <div className="ml-3 flex h-7 items-center">
                                                    <span className="text-left font-light text-xl">{name}</span>
                                                </div>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        onClick={() => { setOpen(false); closeDialogHandler(); }}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <div className="w-full grid grid-cols-1 gap-6 bg-white">
                                                <div className="col-span-1 rounded-lg">
                                                    <img className="w-48 h-48 flex-shrink-0 mx-auto" src={imageUrl} alt="" />
                                                </div>
                                                <div className="w-full border-t border-gray-300" />
                                                <div className="col-span-1 text-left">
                                                    <p className="m-1 text-base text-center font-semibold text-gray-500">{title}</p>
                                                    <p className="mt-4 m-1 text-lg text-left text-gray-600">{description}</p>
                                                </div>
                                                <div class="h-44 grid grid-cols-3 gap-4 content-center ...">
                                                    <div className="border-0 shadow-md rounded-sm flex flex-col sm:py-3 lg:py-12">{stage ? <p><span className='block font-light text-base text-center align-middle lg:text-base'>{stage}</span><span className="block font-light text-base text-center align-middle lg:text-base  text-gray-500">Current stage</span></p> : <></>}</div>
                                                    <div className="border-0 shadow-md rounded-sm flex flex-col sm:py-3 lg:py-12">
                                                        <p>
                                                            <span className="block font-light text-base text-center align-middle lg:text-base">{relationship}</span>
                                                            <span className="block font-light text-base text-center align-middle lg:text-base  text-gray-500">Relationship</span>
                                                        </p>

                                                    </div>
                                                    <div className="border-0 shadow-md rounded-sm flex flex-col sm:py-3 lg:py-12">
                                                        <p>
                                                            <span className="block font-light text-base text-center align-middle lg:text-base ">{years}</span>
                                                            <span className="block font-light text-base text-center align-middle lg:text-base ">Years present</span>
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="w-full border-t border-gray-300" />
                                                <ul role="list" class="flex justify-left space-x-5">
                                                    <li>
                                                        <a href="#" class="text-gray-400 hover:text-gray-300">
                                                            <span class="sr-only text-lg">LinkedIn</span>
                                                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                                                                <path fill-rule="evenodd" d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z" clip-rule="evenodd" />
                                                            </svg>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href={website} class="text-gray-400 hover:text-gray-300">
                                                            <span className="text-lg">{website}</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root >
    )
}
