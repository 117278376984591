import MosheProfilePhoto from "../assets/moshe-profile-photo.jpg"
import EliProfilePhoto from "../assets/eli-profile-photo.jpg"
const people = [
    {
        name: 'Moshe Gabay',
        role: 'Co-founder, Managing Director',
        imageUrl: MosheProfilePhoto,
        bio: 'Moshe is a technologist, executive and programmer with a track record of managing start ups and large scale large product, engineering teams. ',
        linkedinUrl: 'https://www.linkedin.com/in/moshe-gabay-a6792ba/',
    },
    {
        name: 'Elya Khentov',
        role: 'Co-founder',
        imageUrl: EliProfilePhoto,
        bio: 'Eli is a technology generalist with full stack experience in building large scale enterprise and consumer applications.',
        linkedinUrl: '#',
    },
    // More people...
]

export default function About() {
    return (
        <div className="bg-white">
            <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Our Team</h2>
                        <p className="text-gray-500 text-lg text-justify">
                            Like most ex-pats that come to Berlin, we never meant to stay.  Except that that four months after arriving in Berlin (2015), Feingold Technologies a company we were partners and shareholders was aquired by Sharecare (Nasdaq: SHCR). Before we knew it , we established the Berlin R&D operation of Sharecare - Sharecare GmbH  - which grew to be 40 people strong , and spear headed the development of Sharecare's current Digital offering - Sharecare App, Share Cloud Platform , and many other proprietary technologies. Then in 2019, at the brink of the pandemic we decided to start a new company. Brilliant move!
                        </p>
                        <p className="text-base text-gray-500 py-3">If you are wondering about the name , it is the curtsy of the Berlin Business Registration. After a lengthy period of name toasing we decided on these 4 letters put together - N.B.G.K.</p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul
                            role="list"
                            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
                        >
                            {people.map((person) => (
                                <li key={person.name}>
                                    <div className="space-y-4">
                                        <div className="aspect-w-3 aspect-h-2">
                                            <img className="object-cover" src={person.imageUrl} alt="" />
                                        </div>
                                        <div className="text-lg leading-6 font-medium space-y-1">
                                            <h3>{person.name}</h3>
                                            <p className="text-indigo-600">{person.role}</p>
                                        </div>
                                        <div className="text-lg">
                                            <p className="text-gray-500">{person.bio}</p>
                                        </div>

                                        <ul className="flex space-x-5">
                                            <li>
                                                <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                                                    <span className="sr-only">LinkedIn</span>
                                                    <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
