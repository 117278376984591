import React from "react";
import "./App.css";
import Home from "./components/Home"
import Services from "./components/Services"
import About from "./components/About"
import Contact from "./components/Contact"
import Navigation from "./components/Navigation"
import Footer from "./components/Footer";
import Companies from "./components/Companies"

import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="flex flex-col h-screen">
      <BrowserRouter>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/companies" element={<Companies />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;