
import React, { useState } from 'react';
import CompanyDetailDialog from "./CompanyDetailDialog";
import { CompanyDB } from "../components/CompanyDB"

function Companies() {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState('')

    const closeDialog = function () {
        setIsOpen(false);
    }

    const openDialog = function (name) {
        setIsOpen(true);
        setSelectedCompany(name);
    }

    return (
        <div className="relative">
            <div className="py-12 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="m-1 sm:m-2 lg:m-4">
                        <h1 className="text-4xl font-extrabold text-black">Companies</h1>
                    </div>
                    <div className="m-1 sm:m-2 lg:m-4">
                        <p className="text-xl text-gray-500 indent-1">Companies we are building for ourselves and for others</p>
                    </div>
                    <div className="px-2 m-1 sm:m-2 lg:m-4 py-12">
                        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                            {CompanyDB.map((company) => (
                                <li
                                    key={company.name}
                                    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow hover:bg-gray-200"
                                    onClick={() => openDialog(company.name)}
                                >
                                    <div className="flex-1 flex flex-col p-4">
                                        <img className="w-32 h-32 flex-shrink-0 mx-auto" src={company.imageUrl} alt="" />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            {isOpen ? <CompanyDetailDialog closeDialogHandler={closeDialog} companyName={selectedCompany} /> : null}
        </div>
    );
}
export default Companies;