/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, ScaleIcon, FastForwardIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'Startup as a Service',
        description:
            'Wether you are an established company, a start up, a VC  or entrepreneur with a vision we will bring your project from 0 to 1. The outcome of our work is a working engineering organisation, technology and product - all in-your-house and ready for your leadership  to take over smoothly.',
        icon: FastForwardIcon,
    },
    {
        name: 'Technolgy management and executive leadership',
        description:
            'We offer interim executive leadership and hands on technology management to companies that are missing chief technology , enegering management roles.',
        icon: FastForwardIcon,
    },
    {
        name: 'Modernization',
        description:
            'Brining your legacy operation to modernity is like painting a ship at sea. Its hard. We have vast experience in brining legacy operations to a modern technology, migrating to the cloud, reducing operational cost, and building refurbishing systems for scale.',
        icon: FastForwardIcon,
    },
    {
        name: 'Compliance',
        description:
            'You need to implement a compliance protocol for your company. Your legal, compliance admins and engineering team have no idea how to translate the ISMS  to bits and bytes. We will provide hands-on guidance and implementation That is where we come in. ISO 27001, certified HIPPA certified, Hi-Trust certified.',
        icon: ScaleIcon,
    },
    {
        name: 'Knowledge Insurance',
        description:
            'Redundancy at every post is not realistic for SMBs. With a high-churn labour market, SMBs are often at a real risk to perserve their working knowledge and to  keep their technology operations afloat. At NBGK we have help several companies remove this risk by gathering hands-on experience of the technology operation so that when times are tough, there is 100% guarentee that the business remains in tact.',
        icon: AnnotationIcon,
    },
    {
        name: 'Production Issues',
        description:
            'We are extremely good at debugging and identifying operational issues in reasonable time. Moreover, we have helped companies solve major production outages and sevrvice level degrations. We can help stabalize your production operation and ensure that you get the performance you expect from your technology.',
        icon: AnnotationIcon,
    },
]

export default function Services() {
    return (
        <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:text-center">
                    <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Services</h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        We deliver outcomes
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        We offer <span className="decoration-blue-600 underline">an outcome</span> based service. We take bottom-line risks with our customers. And we commited to impact. <span className="decoration-blue-600 underline">Try Us</span>
                    </p>
                </div>

                <div className="mt-10">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                                    </div>
                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>

                <div className="bg-white overflow-hidden">
                    <div className="px-4 py-5 sm:p-6">{/* Content goes here */}</div>
                </div>
            </div>
        </div >
    )
}
