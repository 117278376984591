import PoweredLogo from "../assets/powered-logo-256.png"
import GoodiezLogo from "../assets/goodiez-logo-256.png"
import PollyLogo from "../assets/polly-logo-256.png"
import BackpackLogo from "../assets/backpack-logo-200.png"
import SharecareLogo from "../assets/sharecare-logo-256.png"
import FeingoldTechLogo from "../assets/feingoldtech-logo-256.png"

export const CompanyDB = [
    {
        name: 'Powered.energy',
        title: 'Changing the way the world is POWERED',
        email: 'moshe.gabay@powered.energy',
        telephone: '+4915140190214',
        description: 'Powered is technology company that is sitting on the intersection of EnergyTech and FinTech. It is focused on solving utility billing for the short term rental market.',
        website: 'https://www.powered.energy',
        imageUrl: PoweredLogo,
        stage: 'In development',
        relationship: 'co-founders',
        services: 'Startup as a Service',
        impact: 'We partners',
        years: '2021 - present'
    },
    {
        name: 'Polymed',
        title: 'Polinating healthcare',
        email: 'nbgk@example.com',
        telephone: '+4915140190214',
        description: 'Polymed has an innnovative solution to democratise healthcare. We believe that we have a kernel idea how to make information immidiate to patients, providers and payers.',
        website: 'https://www.polymed.com',
        imageUrl: PollyLogo,
        stage: 'Stealth',
        relationship: 'Founders',
        services: 'Startup as a Service',
        impact: 'We are building Polymed from the group up as part of our Start up as Service program.',
        years: '2021 - present'
    },
    {
        name: 'Goodiez',
        title: 'Good things, small packages',
        email: 'nbgk@example.com',
        telephone: '+4915140190214',
        description: 'Goodiez is revolutionizing assortment technologies for e-commerce',
        website: 'https://www.goodiez.ai',
        imageUrl: GoodiezLogo,
        stage: 'In development',
        relationship: 'Client',
        services: 'Technology managment and leadership',
        impact: 'We are providing Technology management to Goodiez and helping the company go from 0 to 1.',
        years: '2021 - present'
    },
    {
        name: 'Backpack.io',
        title: 'While label trading platform',
        email: 'nbgk@example.com',
        telephone: '+4915140190214',
        description: 'We are helping Backpack.io bridge CeFi and DeFi by building the Web3 tool kit for brokers and gaming operators',
        website: 'https://www.tradesmarter.com',
        imageUrl: BackpackLogo,
        stage: 'Established',
        relationship: 'Client',
        services: 'Technology management and leadership, Modernization, Production Issues, Development',
        impact: 'We have helped Backpack.io through several stages in its life cycle.',
        years: '2019 - present'
    },
    {
        name: 'Sharecare Inc.',
        title: 'Digital health',
        email: 'nbgk@example.com',
        telephone: '+4915140190214',
        description: 'Sharecare Inc. (SHCR) is the digital health company that puts all your health in one place.',
        website: 'https://www.sharecare.com',
        imageUrl: SharecareLogo,
        stage: 'Public',
        relationship: 'Shareholders',
        services: 'Former employees',
        impact: 'We had the privilledge and the hounor help build the Sharecare platform. Together with a band of great friends we built the Sharecare App and the organization around it, Sharecare cloud platform and various other proprietary technologies.',
        years: '2015 - 2020'
    },
    {
        name: 'Feingold Technologies GmbH',
        title: 'Emnotional Intelligence',
        email: 'nbgk@example.com',
        telephone: '+4915140190214',
        description: '',
        website: 'https://www.sharecare.com',
        imageUrl: FeingoldTechLogo,
        stage: 'Aquired',
        relationship: 'Shareholders',
        impact: 'We have helped Feingold Technologies through several stages in its life cycle.',
        years: '2010 - 2015'
    }
]

export default { CompanyDB }